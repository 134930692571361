/* defining css variables */
:root {
  --heading: 20px;
  --subheading: 14px;
  --text: 15px;
  --hue: 204;
  --saturation: 66.8%;
  --lightness: 57.5%;
/*light mode*/
  --body-light: var(--hue) 72.4% 94.3% ;
  --status-light: var(--hue) 0% 100%;
  --text-light: black;
  --shadow-light: 3px 3px 8px #888888;
  --text-shadow-light: 1px 1px 5px white;
/*dark mode*/
  --body-dark: var(--hue) 16.7% 16.5% ;
  --status-dark: var(--hue) 95.2% 8.2%;
  --text-dark: white;
  --shadow-dark: none;
  --text-shadow-dark: 1px 1px 5px black;

  --button_color: var(--hue) var(--saturation) var(--lightness);
}

@media (prefers-color-scheme: dark) {
  :root {
    --body_background: var(--body-dark);
    --status_background: var(--status-dark);
    --text_color: var(--text-dark);
    --box_shadow: var(--shadow-dark);
    --text_shadow: var(--text-shadow-dark)
    color-scheme: dark;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --body_background: var(--body-light);
    --status_background: var(--status-light);
    --text_color: var(--text-light);
    --box_shadow: var(--shadow-light);
    --text_shadow: var(--text-shadow-light);
    color-scheme: light;
  }
}

[color-scheme="dark"]{
  --body_background: var(--body-dark);
  --status_background: var(--status-dark);
  --text_color: var(--text-dark);
  --box_shadow: var(--shadow-dark);
  --text_shadow: var(--text-shadow-dark);
  color-scheme: "dark";
}

[color-scheme="light"]{
  --body_background: var(--body-light);
  --status_background: var(--status-light);
  --text_color: var(--text-light);
  --box_shadow: var(--shadow-light);
  --text_shadow: var(--text-shadow-light);
  color-scheme: "light";
}

* {
  transition: all 0.3s ease-in-out;
}

html, body, #root{
  background-color: hsl(var(--body_background));
}

body{
  margin-bottom: 20px;
  background-color: hsl(var(--body_background));
}

button{
  background-color: rgb(16,179,209);
}

.root{
  background-color: hsl(var(--body_background));
  margin: 0;
  height: 100%;
}

.main{
  background-color: hsl(var(--body_background));
  color: var(--text_color);
  height: 100%;
}

.main input{
  background-color: hsl(var(--body_background));
  border: none;
  color: var(--text_color);
}

.my-button{
  background-color: hsl(var(--button_color));
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  color: var(--text_color);
  text-shadow: var(--text_shadow);
  min-width: 90px;
}
.my-button:hover {
  filter: brightness(85%);
}
@media (max-width: 1024px) { /* Tablet and below */
  .my-button, button {
    padding: 10px;
    width: auto;
  }
}

.homelayout {
  margin: 0;
}

.feed{
    width: 40%;
    margin-left: 30%;
    margin-top: 20px;
    /* overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain; */
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .feed::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

@media (max-width: 1024px) { /* Tablet screens */
  .feed {
    width: 80%;
    margin-left: 10%; /* Adjust the left margin */
  }
}

@media (max-width: 768px) { /* Smaller screens like tablets */
  .feed {
    width: 80%;
    margin-left: 10%; /* Adjust the left margin */
  }
}

@media (max-width: 480px) { /* Mobile screens */
  .feed {
    width: 100%;
    margin-left: 0;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: hsl(var(--button_color));
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.load-container{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emoji{
  display: inline;
}